<template>
  <div>
    <traffic-notice></traffic-notice>
    <bottom-button></bottom-button>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { components } from "./options";

@Component({ components })
class insuranceNotice extends Vue {}
export default insuranceNotice;
</script>
<style lang="scss" src="../index.scss" scoped></style>
